import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import List from '../../components/List'
import LayoutListView from '../../components/LayoutListView'
import { sortBySortOrder } from '../../shared/utilities'

const Leadership = ({ location }) => {
  const configID = '1RIkwLEnY30nZK5aNvNRbC'
  const queryData = useStaticQuery(graphql`
    {
      allContentfulOurTeam(
        sort: { fields: sortOrder, order: ASC }
        filter: { team: { in: ["National", "Board"] } }
      ) {
        edges {
          node {
            id
            team
            profileName
            profileTitle
            profileEmail
            organisation
            sortOrder
            profilePhoto {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const data = queryData.allContentfulOurTeam.edges
    .map(obj => ({
      id: obj.node.id,
      name: obj.node.profileName,
      title: obj.node.profileTitle,
      organisation: obj.node.organisation,
      photo: obj.node.profilePhoto,
      email: obj.node.profileEmail,
      sortOrder: obj.node.sortOrder,
      team: obj.node.team || []
    }))
    .sort(sortBySortOrder)

  console.log(data.map(item => item.sortOrder))
  return (
    <LayoutListView path={location.pathname} configID={configID}>
      <List data={data} itemType="team" className="items-container" />
    </LayoutListView>
  )
}

export default Leadership

Leadership.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
